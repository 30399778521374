import React from 'react'

class Catering extends React.Component {
  render () {
    return (
      <section id='pricing'>

        <div className='row section-intro'>
          <div className='col-twelve with-bottom-line'>

            {/* <h5>Our Pricing</h5> */}
            <h1>Catering Menu.</h1>

            {/* <p className='lead'>Following are the recipes that our RoboChef suggest you to try atleast once in your lifetime.</p> */}

          </div>
        </div>

        <div class="row pricing-content">

         <div class="pricing-tables block-1-4 group">

            <div class="bgrid">

            	<div class="price-block">

            		<div class="top-part">
                  {/* <img loading="lazy" src="images/recipes/prawn-masala2.jpeg" alt="Mushroom Cooking Recipe Indian by RoboChef" /> */}
	            		<h3 class="plan-title">Value Tiffin</h3>
	            	</div>

	               <div class="bottom-part">

	            		<ul class="features">
                    <li>Kesari</li>
                    <li>Thengai chutney</li>
                    <li>Medhu vadai</li>
                    <li>Vadakari</li>
                    <li>Idly</li>
                    <li>Venpongal</li>
                    <li>Tiffin sambar</li>
                    <li>Coffee</li>
		               </ul>

		               {/* <a class="button large" href="https://robotickitchen.in/blog/prawn-masala/">Order Now</a> */}

	            	</div>

            	</div>

			   </div>

            <div class="bgrid">

            	<div class="price-block primary">

            		<div class="top-part" data-info="recommended">
                  {/* <img loading="lazy" src="images/recipes/coconut-rice.jpeg" alt="Dal Rice Cooking Recipe Indian by RoboChef" /> */}
	            		<h3 class="plan-title">Royal Tiffin</h3>


	            	</div>

	               <div class="bottom-part">

	            		<ul class="features">
                  <li>Sweet</li>
                  <li>Thengai chutney</li>
                  <li>Medhu vadai</li>
                  <li>Vadakari</li>
                  <li>Idly</li>
                  <li>Venpongal</li>
                  <li>Tiffin sambar</li>
                  <li>Poori - Potato masala</li>
                  <li>Idiyappam - veg kuruma</li>
                  <li>Coffee</li>
		               </ul>

		               {/* <a class="button large" href="https://robotickitchen.in/blog/coconut-rice/">Order Now</a> */}

	            	</div>

            	</div>

			  </div>

           <div class="bgrid">

               <div class="price-block">

            		<div class="top-part">
                  {/* <img loading="lazy" src="images/recipes/egg-kurma.jpeg" alt="Kadai Paneer Cooking Recipe Indian by RoboChef" /> */}
	            		<h3 class="plan-title"> King Tiffin</h3>

	            	</div>

						<div class="bottom-part">

	            		<ul class="features">
                    <li>Sweet - 1</li>
                    <li>Sweet - 2</li>
                    <li>Thengai chutney</li>
                    <li>Medhu vadai</li>
                    <li>Vadakari</li>
                    <li>Idly</li>
                    <li>Venpongal</li>
                    <li>Tiffin sambar</li>
                    <li>Dhosai (or) Oothappam</li>
                    <li>Idiyappam - veg kuruma</li>
                    <li>Coffee</li>
		               </ul>

		               {/* <a class="button large" href="https://robotickitchen.in/blog/egg-kurma/">Order Now</a> */}

	            	</div>

            	</div>

			   </div>

			   <div class="bgrid">

               <div class="price-block">

            		<div class="top-part">
                  {/* <img loading="lazy" src="images/recipes/paneer-butter.jpeg" alt="Pepper Chicken Cooking Recipe Indian by RoboChef" /> */}
	            		<h3 class="plan-title">Budget Tiffin</h3>

	            	</div>

						<div class="bottom-part">

	            		<ul class="features">
                    <li>Kesari</li>
                    <li>Thengai chutney</li>
                    <li>Medhu vadai</li>
                    <li>Idly</li>
                    <li>Venpongal</li>
                    <li>Tiffin sambar</li>
		               </ul>

		               {/* <a class="button large" href="https://robotickitchen.in/blog/paneer-butter-masala/">Order Now</a> */}

	            	</div>

            	</div>

			   </div> 

         <div class="bgrid">

               <div class="price-block">

            		<div class="top-part">
                  {/* <img loading="lazy" src="images/recipes/tamarind-rice.jpeg" alt="French Fries Cooking Recipe Indian by RoboChef" /> */}
	            		<h3 class="plan-title">Value Meals</h3>

	            	</div>

						<div class="bottom-part">

	            		<ul class="features">
                    <li>Varuval</li>
                    <li>Koottu</li>
                    <li>Pickle</li>
                    <li>Appalam</li>
                    <li>Rice</li>
                    <li>Sambar</li>
                    <li>Vathakuzhambu</li>
                    <li>Tomato rasam</li>
                    <li>Curd(Cup)</li>
		               </ul>

		               {/* <a class="button large" href="https://robotickitchen.in/blog/tamarind-rice/">Order Now</a> */}

	            	</div>

            	</div>

			   </div>

         <div class="bgrid">

           <div class="price-block">

             <div class="top-part">
               {/* <img loading="lazy" src="images/recipes/sambar.jpeg" alt="Mushroom Fried Rice Cooking Recipe Indian by RoboChef" /> */}

               <h3 class="plan-title">Royal Meals</h3>


             </div>

              <div class="bottom-part">

               <ul class="features">
               <li>Varuval</li>
                <li>Koottu</li>
                <li>Pickle</li>
                <li>Appalam</li>
                <li>Special Vadai</li>
                <li>Rice</li>
                <li>Sambar</li>
                <li>Vathakuzhambu</li>
                <li>Tomato rasam</li>
                <li>Curd(Cup) - Paayasam</li>
                </ul>

                {/* <a class="button large" href="https://robotickitchen.in/blog/sambar/">Order Now</a> */}

             </div>

           </div>

     </div> 


     <div class="bgrid">

       <div class="price-block">

         <div class="top-part">
           {/* <img loading="lazy" src="images/recipes/potato-fry.jpeg" width="460" height="445" alt="Egg Podimas Cooking Recipe Indian by RoboChef" /> */}
           <h3 class="plan-title">King Meals</h3>


         </div>

          <div class="bottom-part">

           <ul class="features">
                      <li>Sweet</li>
              <li>Thayir pachadi - Varuval</li>
              <li>Koottu - Poriyal</li>
              <li>Pickle, Appalam</li>
              <li>Special Vadai</li>
              <li>Chappathi - Veg Kuruma</li>
              <li>Veg Biriyani</li>
              <li>Rice</li>
              <li>Sambar, Vathakuzhambu</li>
              <li>Tomato rasam</li>
              <li>Curd(Cup) - Paayasam</li>
            </ul>

            {/* <a class="button large" href="https://robotickitchen.in/blog/potato-fry/">Order Now</a> */}

         </div>

       </div>

 </div> 

 <div class="bgrid">

   <div class="price-block primary">

     <div class="top-part" data-info="recommended">
    {/* <img loading="lazy" src="images/recipes/s-pongal.jpeg" width="460" height="445" alt="Carrot Halwa Cooking Recipe Indian by RoboChef" />  */}
       <h3 class="plan-title">Budget Meals</h3>

     </div>

      <div class="bottom-part">

       <ul class="features">
          <li>Koottu</li>
          <li>Pickle</li>
          <li>Appalam</li>
          <li>Rice</li>
          <li>Sambar</li>
          <li>Tomato rasam</li>
          <li>Buttermilk</li>
        </ul>

        {/* <a class="button large" href="https://robotickitchen.in/blog/sakkarai-pongal/">Order Now</a> */}

     </div>

   </div>

</div> 


         </div>

      </div> 
      </section>
    )
  }
}

export default Catering
