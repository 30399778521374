import React from 'react'

class Header extends React.Component {

  appendScript (filePath) {
    const script = document.createElement('script')
    script.src = filePath
    document.body.appendChild(script)
  }

  myFunction() {
    window.location.reload(true)
  }

  componentDidMount () {
    let scriptPaths = [
      '/js/modernizr.js',
      '/js/main.js',
      '/js/plugins.js',
      '/js/jquery-migrate-1.2.1.min.js',
      '/js/jquery.validate.min.js',
      '/js/jquery-1.11.3.min.js'
      ]
    scriptPaths.forEach(filePath => {
      if (document.body.innerHTML.indexOf(filePath) === -1) {
        this.appendScript(filePath)
      }
    })
  }

  render(){
  return (
  <header>
    <div className='row'>
      <div className='logo'>
        <a href='index.html'>RoboChef</a>
      </div>

      <nav id='main-nav-wrap'>
        <ul className='main-navigation'>
          <li><a className='smoothscroll' href='/#intro' title=''>Home</a></li>
          <li><a className='smoothscroll' href='/#process' title=''>Process</a></li>
          <li><a className='smoothscroll' href='/#features' title=''>Features</a></li>
          <li><a className='smoothscroll' href='/#pricing' title=''>Dishes</a></li>
          <li className='current'><a className='smoothscroll' href='/catering' title=''>Catering</a></li>
          <li><a className='smoothscroll' href='/#faq' title=''>Contact</a></li>
          <li><a href="/blog/" id="reload_b" title=''>Blog</a></li>
          <li><a href='/press' id="reload_p" title=''>Press</a></li>
          {/* <li class="highlight with-sep"><a href="#" title="">Sign Up</a></li> */}
        </ul>
      </nav>

      <a className='menu-toggle' href='#'><span>Menu</span></a>
    </div>
  </header>
  )
  }
}

export default Header

