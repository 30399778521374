import React from 'react'

import Layout from '../components/clayout'
import Catering from '../components/index/catering'
import CateringIntro from '../components/index/cateringintro'
import Slider1 from '../components/index/slide1'

export default ({ data }) => {
  return (
    <Layout>
      <CateringIntro />
      <Catering />
      <Slider1 />
    </Layout>
  )
}
